import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ACTIONS_PREFIX, GOOGLE_API_KEY, YANDEX_API_KEY } from '../constants';
import getIPToCity, { pureState as ipToCityPureState } from './getIPToCity';
import getCityByDomain from './getCityByDomain';
import toggleFavouriteItem from './toggleFavouriteItem';
import toggleViewedItem from './toggleViewedItem';
import saveSuggestion from './saveSuggestion';
import loadSuggestions from './loadSuggestions';
import toggleViewedPoint from './toggleViewedPoint';
import setSelectedCity from './setSelectedCity';
import saveClaimForm from './saveClaimForm';
import fetchTitles from './fetchTitles';
import fetchConfig from './fetchConfig';

export const pureState = {
	tokens: {
		googleMapsApiKey: GOOGLE_API_KEY,
		yandexMapsApiKey: YANDEX_API_KEY,
	},
	hosts: {
		strapi: process.env.STRAPI_HOST,
		api: process.env.API_HOST,
	},
	forceAllImagesPreloaders: false,
	...ipToCityPureState,
	query: {},
	params: {},
	seed: 1,
	locale: {
		current: null,
		locales: [],
	},
	modals: {},
	cityFromUrl: null,
	isGlobalLoading: false,
	requests: [],
	cookieParams: { path: '/', domain: '.spotlyst.ru' },
	...toggleFavouriteItem.pureState,
	...toggleViewedItem.pureState,
	...saveSuggestion.pureState,
	...toggleViewedPoint.pureState,
	...setSelectedCity.pureState,
	...saveClaimForm.pureState,
	...fetchTitles.pureState,
	...fetchConfig.pureState,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		setQuery: (state, action) => {
			return {
				...state,
				query: {
					...action.payload,
				},
			};
		},
		setCityFromCookies: (state, action) => {
			const { city } = action.payload;
			return {
				...state,
				selectedCity: city,
			};
		},
		setParams: (state, action) => {
			return {
				...state,
				params: {
					...action.payload,
				},
			};
		},
		setLocale: (state, action) => {
			return {
				...state,
				locale: {
					...action.payload,
				},
			};
		},
		setRouterCtx: (state, action) => {
			return {
				...state,
				params: {
					...action.payload.params,
				},
				query: {
					...action.payload.query,
				},
				resolvedUrl: action.payload.resolvedUrl,
			};
		},
		setCityFromUrl: (state, action) => {
			const { cityData } = action.payload;
			return {
				...state,
				cityFromUrl: {
					...cityData,
				},
			};
		},
		toggleModal: (state, action) => {
			const { modalId, isOpen, params } = action.payload;

			const modals = {
				...state.modals,
			};

			if (isOpen) {
				modals[modalId] = { ...modals[modalId], isOpen, ...params };
			} else {
				delete modals[modalId];
			}

			return {
				...state,
				modals,
			};
		},
		setGlobalLoading: (state, action) => {
			const { isLoading } = action.payload;

			return {
				...state,
				isGlobalLoading: isLoading,
			};
		},
		setFavourites: (state, action) => {
			const { favourites } = action.payload;
			return {
				...state,
				favourites,
			};
		},
		setSoul: (state, action) => {
			const { soul } = action.payload;
			return {
				...state,
				soul,
			};
		},
		setViewed: (state, action) => {
			const { viewed } = action.payload;
			return {
				...state,
				viewed,
			};
		},
		setComMethods: (state, action) => {
			const { variant } = action.payload;
			return {
				...state,
				ab_com_methods: variant,
			};
		},
		setViewedPoints: (state, action) => {
			const { viewedPoints } = action.payload;
			return {
				...state,
				viewedPoints,
			};
		},
		closeAllModalsWithAnimation: (state) => {
			const modals = { ...state.modals };

			Object.keys(modals).forEach((modalId) => {
				if (modals[modalId].isIn) {
					modals[modalId] = {
						...modals[modalId],
						isIn: false,
					};
				} else {
					delete modals[modalId];
				}
			});

			return {
				...state,
				modals,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase('REQUEST_LOG', (state, action) => {
			return {
				...state,
				requests: [...state.requests, action.payload.url],
			};
		});

		builder.addCase(HYDRATE, (state, action) => {
			const { payload } = action;

			// Проброс логируемых запросов во фронт
			const { requests = [] } = payload.Session || {};
			requests.forEach((url) => {
				fetch(url);
			});

			// Разделение состояний на серверное/клиентское
			return {
				...state,
				...payload.Session,
				ipToCity: payload.Session.ipToCity.data
					? payload.Session.ipToCity
					: state.ipToCity,
				isGlobalLoading: state.isGlobalLoading,
				formerSuggestions: state.formerSuggestions,
				selectedCity:
					payload.Session.selectedCity || state.selectedCity,
			};
		});

		getIPToCity.reducerFactory(builder);
		getCityByDomain.reducerFactory(builder);
		toggleFavouriteItem.reducerFactory(builder);
		toggleViewedItem.reducerFactory(builder);
		saveSuggestion.reducerFactory(builder);
		loadSuggestions.reducerFactory(builder);
		toggleViewedPoint.reducerFactory(builder);
		setSelectedCity.reducerFactory(builder);
		saveClaimForm.reducerFactory(builder);
		fetchTitles.reducerFactory(builder);
		fetchConfig.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	toggleFavouriteItem: toggleFavouriteItem.action,
	toggleViewedItem: toggleViewedItem.action,
	saveSuggestion: saveSuggestion.action,
	toggleViewedPoint: toggleViewedPoint.action,
	saveClaimForm: saveClaimForm.action,
};

export const { reducer } = set;
