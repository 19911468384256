import React from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useRouter } from 'next/router';
import useDeepUpdate from '@src/libs/hooks/useDeepUpdate';

import { useActions } from './useActions';
import { actions as sessionActions } from '../reducers';
import fetchTitles from '../reducers/fetchTitles';

const useRouterWatcher = () => {
	const dispatch = useDispatch();
	const { setGlobalLoading } = useActions();
	const router = useRouter();
	const { asPath, query, pathname } = router;

	useDeepUpdate(() => {
		const p = {};
		const q = {};

		Object.keys(query).forEach((prop) => {
			if (pathname.includes(`[${prop}]`)) {
				p[prop] = query[prop];
			} else {
				q[prop] = query[prop];
			}
		});

		dispatch(
			sessionActions.setRouterCtx({
				params: p,
				query: q,
				resolvedUrl: asPath,
			}),
		);

		if (window.location.pathname === '/') {
			dispatch(fetchTitles.action({ type: 'homepage' }));
		} else if (pathname === '/[town]' && Object.keys(q).length === 0) {
			dispatch(fetchTitles.action({ type: 'listing_root' }));
		} else {
			dispatch(fetchTitles.action());
		}
	}, [query, pathname, asPath]);

	React.useEffect(() => {
		const handleRouteChangeStart = (_url, { shallow }) => {
			if (!shallow) {
				setGlobalLoading({ isLoading: true });
			}
		};

		const handleRouteChangeComplete = (_url, { shallow }) => {
			if (!shallow) {
				setGlobalLoading({ isLoading: false });
			}
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
			router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
	}, [router, setGlobalLoading]);

	useUpdateEffect(() => {
		const pureQuery = asPath.split('?')[1];
		const parsedQuery = pureQuery ? qs.parse(pureQuery) : {};
		const params = { ...query };
		Object.keys(parsedQuery).forEach((key) => {
			delete params[key];
		});

		dispatch(
			sessionActions.setRouterCtx({
				query: parsedQuery,
				params,
				resolvedUrl: asPath,
			}),
		);
	}, [query, asPath]);
};

export default useRouterWatcher;
