import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	fetchConfig: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchConfig = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchConfig`,
	async (_options = {}, { extra }) => {
		const { api } = extra;
		const strapiRes = await api.strapi.get(`site-config`);
		const data = strapiRes.data?.data?.attributes || {};
		return data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchConfig.pending, (state) => {
		return {
			...state,
			fetchConfig: {
				...state.fetchConfig,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchConfig.fulfilled, (state, action) => {
		return {
			...state,
			fetchConfig: {
				...state.fetchConfig,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchConfig.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			fetchConfig: {
				...state.fetchConfig,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { message: action.error.message, arg: meta.arg || null },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchConfig,
};
